import React from "react"
import { AnimatePresence } from "framer-motion"

import "@styles/reset.scss"
import "@styles/fonts.scss"
import "@styles/globals.scss"

/**
 * Wrap Page Element
 * @link https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)
/**
 * Should Update Scroll
 * @link https://janessagarrow.com/blog/gatsby-framer-motion-page-transitions
 */
// Wait until page exit animation has completed before updating scroll position
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Transition duration from `layout.js` * 1000 to get time in ms
  const TRANSITION_DELAY = 0.3 * 1000 * 2
  // If it's a "normal" route
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // If we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
